<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete mapping rule"
    description="Deleting this mapping rule may change distribution in linked rotation boxes. Are you sure you want to delete the rule?"
    primary-button-text="Delete"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteMapping"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import type { Mapping } from '@/types'
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  mapping: Mapping
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const deleteMapping = async () => {
  loading.value = true
  try {
    await useDeleteMapping(Number(props.mapping.id))
    emits('input')
    emits('update:modelValue', false)
  } catch (error) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
